<template>
  <div :class="['CEESAR-MessageLabel', 'CEESAR-MessageLabel-' + format]">
    <slot />
  </div>
</template>

<script>
export default {
    name: "CEESAR-MessageLabel",
    props:{
        format: {type: String, default: "Information"}
    }
}
</script>

<style>

</style>